<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import { VclList } from "vue-content-loading";
import VueMonthlyPicker from "vue-monthly-picker";
import moment from "moment";

export default {
  locales: {
    en: {
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    pt: {
      months: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],

      Unilevel: "Unilevel",
      Username: "Usuário",
      Name: "Nome",
      Plan: "Plano",
      Team: "Equipe",
      Graduation: "Graduação",
      Monthly: "Mensalidade",
      Inactive: "Inativo",
      Active: "Ativo",
      "Points Unilevel": "Pontos de Unilevel",
      "Points Graduation": "Pontos de Graduação",
      "Unilevel | Graduation": "Unilevel | Graduação",
      View: "Ver",
      Sponsoreds: "Indicados",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      months: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],

      Unilevel: "Unilevel",
      Username: "Nombre de Usuario",
      Name: "Nombre",
      Plan: "Plano",
      Team: "Equipo",
      Graduation: "Graduación",
      Monthly: "Mensalidade",
      Inactive: "Inativo",
      Active: "Ativo",
      "Points Unilevel": "Puntos de Unilevel",
      "Points Graduation": "Puntos de Graduación",
      "Unilevel | Graduation": "Unilevel | Graduación",
      View: "Ver",
      Sponsoreds: "Indicados",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: {
    Layout,
    VclList,
    VueMonthlyPicker,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      id: this.$route.params.id,

      calendar: "",
      monthly: {
        placeHolder: moment().format("MMM/YYYY"),
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      date: moment().format("YYYY-MM"),

      user: {
        avatar: "",
        username: "",
        name: "",
        package: "",
        graduation: "",
        monthly: "",
        team: "",
        points: {
          unilevel: "",
          graduation: "",
        },
      },

      table: {
        heade: [
          "#",
          "Name",
          "Plan",
          "Graduation",
          "Monthly",
          "Team",
          "Unilevel | Graduation",
          "",
        ],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
    };
  },
  methods: {
    showDate(date) {
      this.date = date;
    },
    getList() {
      this.table.body = null;

      this.table.loading = true;
      this.table.errored = false;
      this.table.empty = false;

      api
        .get("network/unilevel?id=" + this.id + "&date=" + this.date)
        .then((response) => {
          if (response.data.status == "success") {
            this.user = response.data.user;
            this.table.body = response.data.list;
          } else if (response.data.status == "error") {
            this.$router.push("/network/unilevel");
          }
        })
        .catch((error) => {
          if (error) {
            this.$router.push("/network/unilevel");
          }
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
  },
  mounted() {
    if (isNaN(this.id)) {
      this.id = this.account.user.id;
    }

    this.getList();
  },
  watch: {
    "$route.params.id": function (v) {
      this.id = v;

      if (isNaN(this.id)) {
        this.id = this.account.user.id;
      }

      this.getList();
    },
    calendar: function (v) {
      this.date = moment(v._d).format("YYYY-MM");
      this.getList();
    },
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>{{ t('Unilevel') }}</h3>
        <p></p>
      </div>
      <vue-monthly-picker v-model="calendar" dateFormat="MMM/YYYY" :placeHolder="monthly.placeHolder" :monthLabels="monthly.labels" :clearOption="false" alignment="center"></vue-monthly-picker>
    </div>

    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="card">
          <div class="card-header bg-default rounded-top">
            <div class="d-flex">
              <div class="align-self-center mr-3">
                <img v-if="user.avatar" class="rounded-circle header-profile-user" :src="user.avatar" alt="" style="width: 60px; height: 60px" />
                <img v-else class="rounded-circle header-profile-user" :src="'https://ui-avatars.com/api/?background=177317&color=fff&name=' +
                  user.name
                  " alt="" style="width: 60px; height: 60px" />
              </div>
              <h5 class="mb-0 flex-fill align-self-center font-size-16 text-white">
                {{ user.name }}
              </h5>
              <div class="mb-0 align-self-center text-right">
                <router-link :to="'/network/unilevel/' + user.sponsor" class="h1 text-white">
                  <i class="bx bxs-up-arrow-square text-white"></i>
                </router-link>
              </div>
            </div>
          </div>
          <div class="card-body pt-3">
            <div class="d-flex justify-content-between">
              <div>Plano</div>
              <span class="badge badge-soft-primary font-size-13">{{
                t(user.package)
              }}</span>
            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ t("Status") }}</div>
              <div>
                <span v-if="user.monthly === 'Inactive'" class="badge badge-soft-danger font-size-13">{{ t(user.monthly) }}</span>
                <span v-else-if="user.monthly === 'Active'" class="badge badge-soft-success font-size-13">{{ t(user.monthly) }}</span>
              </div>
            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ t("Graduation") }}</div>
              <span class="badge badge-soft-dark font-size-13">{{
                t(user.graduation)
              }}</span>
            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ t("Team") }}</div>
              <span class="badge badge-soft-warning font-size-13">{{
                user.team
              }}</span>
            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ t("Pontos Pessoais") }}</div>
              <span class="badge badge-soft-success font-size-13">{{
                user.points.personal
              }}</span>
            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ t("Pontos da Equipe") }}</div>
              <span class="badge badge-soft-success font-size-13">{{
                user.points.team
              }}</span>
            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ t("Total de Pontos") }}</div>
              <span class="badge badge-soft-success font-size-13">{{
                user.points.total
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sponsoreds mb-3">
      <span>{{ t("Sponsoreds") }}</span>
    </div>

    <div v-if="table.errored">
      <div class="card">
        <div class="card-body">
          {{
            t(
              "We apologize, we are unable to retrieve information at this time. Please try again later."
            )
          }}
        </div>
      </div>
    </div>
    <div v-else-if="table.empty">
      <div class="card">
        <div class="card-body">{{ t("No records found.") }}</div>
      </div>
    </div>
    <vcl-list v-else-if="table.loading" class="col-lg-6"></vcl-list>
    <div v-else class="row">
      <div class="col-md-6 col-lg-4" v-for="(row, index) in table.body" :key="index">
        <router-link :to="'/network/unilevel/' + row.id" class="text-dark">
          <div class="card">
            <div class="card-header bg-soft-dark rounded-top">
              <div class="d-flex">
                <div class="align-self-center mr-3">
                  <img v-if="row.avatar" class="rounded-circle header-profile-user" :src="row.avatar" alt="" style="width: 60px; height: 60px" />
                  <img v-else class="rounded-circle header-profile-user" :src="'https://ui-avatars.com/api/?background=177317&color=fff&name=' +
                    row.name
                    " alt="" style="width: 60px; height: 60px" />
                </div>
                <h5 class="mb-0 flex-fill align-self-center font-size-16">
                  {{ row.name }}
                </h5>
              </div>
            </div>
            <div class="card-body pt-3">
              <div class="d-flex justify-content-between">
                <div>Plano</div>
                <span class="badge badge-soft-primary font-size-13">{{
                  t(row.package)
                }}</span>
              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ t("Status") }}</div>
                <div>
                  <span v-if="row.monthly === 'Inactive'" class="badge badge-soft-danger font-size-13">{{ t(row.monthly) }}</span>
                  <span v-else-if="row.monthly === 'Active'" class="badge badge-soft-success font-size-13">{{ t(row.monthly) }}</span>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ t("Graduation") }}</div>
                <span class="badge badge-soft-dark font-size-13">{{
                  t(row.graduation)
                }}</span>
              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ t("Team") }}</div>
                <span class="badge badge-soft-warning font-size-13">{{
                  row.team
                }}</span>
              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ t("Pontos Pessoais") }}</div>
                <span class="badge badge-soft-success font-size-13">{{
                  row.points.personal
                }}</span>
              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ t("Pontos da Equipe") }}</div>
                <span class="badge badge-soft-success font-size-13">{{
                  row.points.team
                }}</span>
              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ t("Total de Pontos") }}</div>
                <span class="badge badge-soft-success font-size-13">{{
                  row.points.total
                }}</span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.sponsoreds {
  position: relative;
  text-align: center;
}

.sponsoreds span {
  display: inline-block;
  padding: 0 10px;
  background: #fff;
}

.sponsoreds:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 50%;
  height: 1px;
  background: #ccc;
}
</style>